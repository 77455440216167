import { useEffect } from 'react';
import gsap from 'gsap';
import { useWindowSize } from 'common/hook/useWindowSize';

export const CursorFollower = ({ tooltip }: { tooltip: string }) => {
  const isTouchDevice = typeof window !== 'undefined' ? 'ontouchstart' in window : false;
  const { dimensions } = useWindowSize();

  useEffect(() => {
    const cursor = document.getElementById('cursor');
    const cursorDot = document.getElementById('cursor-dot');
    const cursorTooltip = document.getElementById('cursor-tooltip');
    if (isTouchDevice || !cursor) {
      return;
    }

    let time: any;
    window.addEventListener('mousemove', (e: any) => {
      const { target, x, y } = e;
      const isTargetLinkOrBtn = target?.closest('a') || target?.closest('button');
      if (!time) {
        setTimeout(() => {
          time = new Date();
        }, 1000);
      }
      gsap.to(cursor, {
        x: x + 3,
        y: y + 3,
        duration: 0,
        ease: 'power4',
        opacity: isTargetLinkOrBtn ? 0.6 : 1,
        backgroundColor: isTargetLinkOrBtn ? 'rgba(242, 195, 1, 0.6)' : 'transparent',
        transform: `scale(${isTargetLinkOrBtn ? 1.5 : 1})`,
      });
      gsap.to(cursorDot, {
        x: x + 3,
        y: y + 3,
        duration: time ? 0 : 0,
        ease: 'power4',
        opacity: isTargetLinkOrBtn ? 0.6 : 1,
        backgroundColor: isTargetLinkOrBtn ? 'rgba(242, 195, 1, 0.4)' : 'rgba(242, 195, 1, 1)',
        transform: `scale(${isTargetLinkOrBtn ? 6 : 1})`,
      });

      let cursorTooltipX = x - 150;
      let cursorTooltipY = y + 10;
      if (cursorTooltipX < 20 || x === 0) {
        cursorTooltipX = 20;
      }
      if (cursorTooltipY < 20 || y === 0) {
        cursorTooltipY = 20;
      }

      const cursorTooltip = document.getElementById('cursor-tooltip');
      if (
        cursorTooltip?.offsetHeight &&
        cursorTooltipY + cursorTooltip?.offsetHeight > window.innerHeight
      ) {
        cursorTooltipY = y - cursorTooltipY?.offsetHeight - 20;
      }

      if (window.innerHeight < 500) {
        cursorTooltipX = 20;
      }

      gsap.to(cursorTooltip, {
        x: cursorTooltipX,
        y: cursorTooltipY,
        duration: time ? 1 : 0,
        ease: 'power4',
        opacity: 1,
      });
    });

    document.addEventListener('mouseleave', () => {
      gsap.to(cursor, {
        duration: 0.7,
        opacity: 0,
      });
    });
  }, []);

  return (
    <>
      <div id="cursor" className="cursor" />
      <div id="cursor-dot" className="cursor-dot" />
      <div
        id="cursor-tooltip"
        className="cursor-tooltip"
        style={{
          opacity: tooltip ? 1 : 0,
          padding: tooltip ? 15 : 0,
          paddingLeft: tooltip ? 20 : 0,
          paddingRight: tooltip ? 20 : 0,
        }}>
        {tooltip}
      </div>
    </>
  );
};

export default CursorFollower;
