import React, { createContext, useRef, useState } from 'react';

import clsx from 'clsx';
import gsap from 'gsap/dist/gsap';
import { IoMdClose } from 'react-icons/io';

interface IMessage {
  duration?: number;
  message: string;
  type?: 'error' | 'success';
}

export const ToastMessageContext = createContext<{
  message: IMessage | null;
  hideMessage: (e?: any) => void;
  showMessage: (data: IMessage) => void;
}>({
  message: { message: '', type: undefined },
  hideMessage: (e: any) => {},
  showMessage: (data: IMessage) => {},
});

export const ToastMessage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const el = useRef(null);

  const [message, setMessage] = useState<IMessage | null>(null);

  const hideMessage = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    const q = gsap.utils.selector(el);
    gsap.timeline({ delay: 0 }).to(q('#toast-message'), { duration: 0.3, opacity: 0 });
    gsap
      .timeline({ delay: 0 })
      .to(q('#toast-message-content'), { duration: 0.2, transform: 'translateX(100%)' });
    setTimeout(() => {
      setMessage(null);
    }, 500);
  };

  const showMessage = (data: IMessage) => {
    setMessage(data);
    setTimeout(() => {
      const q = gsap.utils.selector(el);

      gsap.timeline({ delay: 0 }).to(q('#toast-message'), { duration: 0.3, opacity: 1 });
      gsap
        .timeline({ delay: 0 })
        .to(q('#toast-message-content'), { duration: 0.2, transform: 'translateX(0)' });
      setTimeout(() => {
        hideMessage();
      }, data.duration || 5000);
    }, 500);
  };

  return (
    <ToastMessageContext.Provider value={{ message, hideMessage, showMessage }}>
      {children}
      <div ref={el}>
        {!!message?.message && (
          <div
            id="toast-message"
            className="fixed bottom-0 left-0 right-0 top-0 z-[99999] flex h-full w-full cursor-pointer items-start justify-end bg-[#160D21aa] opacity-0"
            onClick={hideMessage}>
            <div
              id="toast-message-content"
              className={clsx(
                'relative m-8 translate-x-[100%] rounded-sm p-8 pr-16 text-white lg:m-20',
                {
                  'bg-red-600': message.type === 'error',
                  'bg-success': message.type === 'success',
                },
              )}
              onClick={hideMessage}>
              <span dangerouslySetInnerHTML={{ __html: message.message }} />
              <button className="absolute right-0 top-0 p-4">
                <IoMdClose
                  className=" fill-white transition-all duration-300 hover:fill-primary"
                  fill="white"
                  size={24}
                  onClick={hideMessage}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </ToastMessageContext.Provider>
  );
};
