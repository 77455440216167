import { useTranslation } from 'react-i18next';

export const useCookieTranslation = () => {
  const { t } = useTranslation('common');

  const privacyPolicySlug = t('privacyPolicySlug');

  const hr = {
    consentModal: {
      title: 'Koristimo kolačiće',
      description: `Na ovoj web stranici koriste se kolačići. Molimo pročitajte <a href="${privacyPolicySlug}">politiku kolačića</a> ili prilagodite svoje postavke.`,
      acceptAllBtn: 'Prihvati sve',
      acceptNecessaryBtn: 'Prihvati nužne',
      showPreferencesBtn: 'Upravljanje postavkama',
    },
    preferencesModal: {
      title: 'Prilagodite kolačiće',
      acceptAllBtn: 'Prihvati sve',
      acceptNecessaryBtn: 'Odbij sve',
      savePreferencesBtn: 'Spremi postavke',
      closeIconLabel: 'Zatvori prozor',
      serviceCounterLabel: 'Usluga|Usluge',
      sections: [
        {
          description:
            "Koristimo kolačiće kako bismo vam omogućili učinkovito kretanje po stranici i obavljanje određenih funkcija. Detaljne informacije o svim kolačićima možete pronaći ispod svake kategorije suglasnosti. Kolačići koji su kategorizirani kao 'Nužni' pohranjuju se u vašem pregledniku jer su ključni za omogućavanje osnovnih funkcionalnosti stranice.",
        },
        {
          title: 'Nužni <span class="pm__badge">Uvijek omogućeni</span>',
          description:
            'Nužni kolačići su potrebni za omogućavanje osnovnih funkcija ove stranice, poput pružanja sigurnog prijavljivanja ili prilagodbe vaših postavki suglasnosti. Ovi kolačići ne pohranjuju nikakve osobne podatke.',
          linkedCategory: 'necessary',
        },
        {
          title: 'Analitika',
          description:
            'Analitički kolačići koriste se za razumijevanje kako posjetitelji stupaju u interakciju s web stranicom. Ovi kolačići pomažu pružiti informacije o metrima kao što su broj posjetitelja, stopa napuštanja, izvor prometa, itd.',
          linkedCategory: 'analytics',
        },
        {
          description:
            'Također koristimo kolačiće trećih strana koji nam pomažu analizirati kako koristite ovu web stranicu, pohranjuju vaše preferencije i pružaju sadržaj i oglase koji su relevantni za vas. Ovi kolačići će se pohraniti u vašem pregledniku samo uz vašu prethodnu suglasnost. Možete odlučiti omogućiti ili onemogućiti neke ili sve ove kolačiće, ali onemogućavanje nekih od njih može utjecati na vaše iskustvo pregledavanja.',
        },
      ],
    },
  };
  const en = {
    consentModal: {
      title: 'We use cookies',
      description: `Cookies are used on this website. Please read the <a href="${privacyPolicySlug}">cookie policy</a> or adjust your settings.`,
      acceptAllBtn: 'Accept all',
      acceptNecessaryBtn: 'Accept necessary',
      showPreferencesBtn: 'Manage preferences',
    },
    preferencesModal: {
      title: 'Customize cookies',
      acceptAllBtn: 'Accept all',
      acceptNecessaryBtn: 'Reject all',
      savePreferencesBtn: 'Save preferences',
      closeIconLabel: 'Close modal',
      serviceCounterLabel: 'Service|Services',
      sections: [
        {
          description:
            'We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below. The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.',
        },
        {
          title: 'Necessary <span class="pm__badge">Always Enabled</span>',
          description:
            'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
          linkedCategory: 'necessary',
        },
        {
          title: 'Analytics',
          description:
            'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
          linkedCategory: 'analytics',
        },
        {
          description:
            'We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent. You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.',
        },
      ],
    },
  };

  return { hr, en };
};
