import { useCallback, useEffect, useRef } from 'react';

import clsx from 'clsx';
import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { i18n, useTranslation } from 'next-i18next';
import Image from 'next/image';

import { MenuModal } from '../MenuModal';

import hamburgerIcon from 'assets/image/icon-hamburger.svg';
import Link from 'next/link';
import { IAnyType } from 'common/types/common';

const logoDark =
  'https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/logo%2FNordit%20logo%20-%20primary%20black%2FNordit%20logo%20-%20primary%20black.svg?alt=media&token=3dc59342-ef7f-4fb2-bd6a-905c15126e99';
const logoWhite =
  'https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/logo%2FNordit%20logo%20-%20primary%20white%2FNordit%20logo%20-%20primary%20white.svg?alt=media&token=53c70a77-ccd9-4fc6-8019-47aba940c62f';

gsap.registerPlugin(ScrollTrigger);

interface Props {
  alternate: { hrefLang: string; slug: string };
  dark?: boolean;
}

export const Header = ({ alternate, dark }: Props) => {
  const { i18n, t } = useTranslation();
  const modalRef = useRef<IAnyType>(null);
  const el = useRef(null);

  const showMenu = () => {
    const visible = modalRef.current?.toggleMenu();
    const q = gsap.utils.selector(el);

    if (visible) {
      gsap.timeline({ delay: 0 }).to(q('#header-menu'), { duration: 0.5, opacity: 0 });
      gsap.timeline({ delay: 0 }).to(q('#header-logo'), { duration: 0.4, opacity: 0 });
      setTimeout(() => {
        document.getElementById('menu-trigger')?.classList.add('ring-2');
        if (!dark) {
          document.getElementById('menu-trigger')?.classList.remove('invert');
        }
        if (dark) {
          document.getElementById('header')?.classList.remove('bg-gradient');
          document.getElementById('header')?.classList.add('bg-transparent');
        } else {
          document.getElementById('header')?.classList.remove('bg-white');
          document.getElementById('header')?.classList.add('bg-transparent');
        }
      }, 0);
    } else {
      gsap.timeline({ delay: 0.8 }).to(q('#header-logo'), { duration: 0.5, opacity: 1 });
      gsap.timeline({ delay: 1 }).to(q('#header-menu'), { duration: 0.5, opacity: 1 });
      setTimeout(() => {
        if (!dark) {
          document.getElementById('menu-trigger')?.classList.add('invert');
        }
      }, 300);
      setTimeout(() => {
        document.getElementById('menu-trigger')?.classList.remove('ring-2');
        if (dark) {
          document.getElementById('header')?.classList.remove('bg-transparent');
          document.getElementById('header')?.classList.add('bg-gradient');
        } else {
          document.getElementById('header')?.classList.remove('bg-transparent');
          document.getElementById('header')?.classList.add('bg-white');
        }
      }, 1000);
    }
  };

  const addListeners = useCallback(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      function adjustMenuIconPosition() {
        if (document) {
          const menuTrigger = document.getElementById('menu-trigger');
          if (menuTrigger) {
            menuTrigger.style.left = '';
            menuTrigger.style.left = `${menuTrigger?.offsetLeft}px`;
          }
        }
      }

      window.addEventListener('resize', adjustMenuIconPosition);
      window.addEventListener('load', adjustMenuIconPosition);
    }
  }, []);

  useEffect(() => {
    addListeners();
    const showAnim = gsap
      .from('.main-tool-bar', {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: self => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, [addListeners]);

  const base = t('common:company.urlAlternate');
  const menu: IAnyType = t('menu.items', { returnObjects: true });
  const otherLanguage = i18n.language === 'en' ? 'HR' : 'EN';
  let otherLanguageSlug = alternate ? `${base}${alternate.slug}` : '';

  return (
    <div ref={el} className="z-[99999]">
      <header
        id="header"
        className={clsx('main-tool-bar fixed -top-40 z-[99999] w-full text-white', {
          'bg-gradient': dark,
          'bg-white': !dark,
        })}>
        <div className="container relative flex items-center justify-between px-6 py-8 lg:py-5">
          <Link href="/">
            <Image
              id="header-logo"
              className="relative w-[130px]"
              src={dark ? logoWhite : logoDark}
              alt="Nordit software development"
              width={412}
              height={70}
            />
          </Link>

          <button className="menu-trigger lg:hidden" onClick={showMenu}>
            <Image src={hamburgerIcon} alt="Menu" width={30} height={30} />
          </button>

          <nav className="flex flex-row">
            <ul
              id="header-menu"
              className={clsx('menu-content hidden items-center justify-center lg:flex', {
                'mr-6': otherLanguageSlug,
                'mr-12': !otherLanguageSlug,
              })}>
              {Array.isArray(menu) &&
                menu.length &&
                menu?.map((item: any) => (
                  <li key={item.link}>
                    <Link href={item.link}>{item.title}</Link>
                  </li>
                ))}
              {otherLanguageSlug && (
                <li key={otherLanguage} className="ml-12 mt-0.5 scale-90">
                  <a href={otherLanguageSlug}>{otherLanguage}</a>
                </li>
              )}
            </ul>
            <button className="relative mt-1.5 hidden lg:block" onClick={showMenu}>
              <Image
                id="menu-trigger"
                className={clsx(
                  'rounded-full ring-white/20 ring-offset-8 ring-offset-white/10 transition-all duration-500 focus:outline-none',
                  { invert: !dark },
                )}
                src={hamburgerIcon}
                alt="Menu"
                width={30}
                height={30}
              />
            </button>
          </nav>
        </div>
      </header>
      <MenuModal
        ref={modalRef}
        otherLanguage={otherLanguage}
        otherLanguageSlug={otherLanguageSlug}
      />
    </div>
  );
};
