import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [dimensions, setDimensions] = useState({
    window:
      typeof window === 'undefined'
        ? { height: 0, width: 0 }
        : { height: window.innerHeight, width: window.innerWidth },
  });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const autoResize = () => {
      setDimensions({ window: { height: window.innerHeight, width: window.innerWidth } });
    };

    window.addEventListener('resize', autoResize);
    autoResize();
    return () => window.removeEventListener('resize', autoResize);
  }, []);

  return { dimensions };
};
