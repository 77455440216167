import Head from 'next/head';
import { useTranslation } from 'next-i18next';

interface Props {
  title: string;
  description: string;
  image: string;
  schemaMarkup?: any[];
  slug: string;
  alternate?: { hrefLang: string; slug: string };
}

export const Seo = (props: Props) => {
  const { t } = useTranslation('common');
  const base = t('company.url');
  const baseAlternate = t('company.urlAlternate');
  const url = props.slug ? `${base}${props.slug}` : base;
  let alternateUrl = props.alternate ? `${baseAlternate}/${props.alternate.slug}` : '';
  if (alternateUrl) {
    alternateUrl = alternateUrl.replace(/\/\//g, '/');
  }
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{props.title}</title>
      <link rel="canonical" href={url} />
      {!!props.alternate && (
        <link rel="alternate" hrefLang={props.alternate.hrefLang} href={alternateUrl} />
      )}
      <meta name="description" content={props.description as string} />

      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#F2C301" />
      <meta name="msapplication-TileColor" content="#F2C301" />
      <meta name="theme-color" content="#000000" />

      <meta name="author" content="Nordit d.o.o. - info@nordit.co" />
      <meta name="theme-color" content="#F2C301" />

      <meta property="og:description" content={props.description as string} />
      <meta
        property="og:image"
        content={
          props.image ||
          'https://firebasestorage.googleapis.com/v0/b/eudoctor-3d58e.appspot.com/o/production%2Fweb%2FeudoctorOGImage.png?alt=media&token=7b261d78-d607-4eae-8add-6f95dd28cbdc'
        }
      />
      <meta property="og:title" content={props.title as string} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      {!!props.schemaMarkup && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(props.schemaMarkup) }}
        />
      )}
    </Head>
  );
};
