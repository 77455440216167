import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';

import { motion } from 'framer-motion';
import { gsap } from 'gsap';

import { Footer } from 'common/components/Footer';
import { Header } from 'common/components/Header';
import { Cookies } from 'common/components/CookieConsent';
import CursorFollower from 'common/components/CursorFollower';
import { ToastMessage } from 'common/components/ToastMessage';
import { IAnyType } from 'common/types/common';
import { Seo } from 'common/components/SEO';

import { anim, expand, opacity } from './anim';

export const TooltipContext = createContext({
  tooltip: '',
  handleSetTooltip: (_val: string) => {},
});

const NUMBER_OF_COLUMNS = 8;

export const Layout = ({ children, className, dark, seo, footerBorderTop }: IAnyType) => {
  const el = useRef(null);
  const q = gsap.utils.selector(el);

  const [tooltip, setTooltip] = useState('');

  const handleSetTooltip = (value: string) => {
    setTooltip(value);
  };

  const onScroll = useCallback(() => {
    if (tooltip) {
      setTooltip('');
    }
  }, [tooltip]);

  useEffect(() => {
    gsap.timeline({ delay: 1 }).to(q('header'), { duration: 0.6, top: 0 });
  }, [q]);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [tooltip]);

  return (
    <>
      <Seo {...seo} />
      <div className="page stairs">
        <motion.div {...anim(opacity)} className="transition-background" />
        <div className="transition-container">
          {[...Array(NUMBER_OF_COLUMNS)].map((_, i) => {
            return <motion.div key={i} {...anim(expand, NUMBER_OF_COLUMNS - i)} />;
          })}
        </div>

        <ToastMessage>
          <TooltipContext.Provider value={{ handleSetTooltip, tooltip }}>
            <div ref={el} className={className}>
              <Header alternate={seo.alternate} dark={dark} />
              <main>{children}</main>

              <Footer borderTop={footerBorderTop} />

              <CursorFollower tooltip={tooltip} />
            </div>
          </TooltipContext.Provider>
        </ToastMessage>
      </div>

      <Cookies />
    </>
  );
};
