import { IAnyType } from 'common/types/common';

export const expand = {
  initial: {
    top: 0,
  },
  enter: (i: number) => ({
    top: '100vh',
    transition: {
      duration: 1,
      delay: 0.1 * i,
      ease: [0.215, 0.61, 0.355, 1],
    },
    transitionEnd: { height: '0', top: '0' },
  }),
  exit: (i: number) => ({
    height: '100vh',
    transition: {
      duration: 1,
      delay: 0 * i,
      ease: [0.215, 0.61, 0.355, 1],
    },
  }),
};

export const opacity = {
  initial: { opacity: 0.5 },
  enter: { opacity: 0 },
  exit: { opacity: 0.5 },
};

export const anim = (variants: IAnyType, custom?: IAnyType) => {
  return {
    initial: 'initial',
    animate: 'enter',
    exit: 'exit',
    custom,
    variants,
  };
};
